import React from "react"
import { graphql } from "gatsby"
import { generatePath, editionIsReleased } from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function NotFound({ data }) {
  const { editions } = data
  const releasedEditions = editions.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })

  return (
    <>
      <WebsiteMeta titleFull="The Priory Press" />
      <FadeIn direction="up">
        <PageTitle>
          Page not found{" "}
          <span role="img" aria-label="sad face emoji">
            😔
          </span>
        </PageTitle>
      </FadeIn>
      <FadeIn direction="down" delay={250}>
        <Subheader>Here's a list of our recent editions:</Subheader>
      </FadeIn>

      {releasedEditions.length > 0 && (
        <FadeIn direction="up" delay={250}>
          <Editions>
            {releasedEditions.map(({ node }, index) => (
              <ListElement
                link={generatePath(node.slug.current, "edition")}
                title={node.name}
                extras1="Released "
                line2={node.formattedRelease}
                index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                key={node._id}
              />
            ))}
          </Editions>
        </FadeIn>
      )}
    </>
  )
}

export const pageQuery = graphql`
  query {
    editions: allSanityEdition(sort: { fields: [release], order: DESC }) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }
  }
`

const PageTitle = styled.h1`
  margin: 0;
`

const Subheader = styled.p`
  margin: 0.25rem 0 1.25rem 0;
  color: var(--gray);
`

const Editions = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
`
